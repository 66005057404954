<template>
  <div class="flex mt-2">
    <div class="overflow-x-auto w-full min-w-xl h-modal pb-5 px-3">
      <div>
        <table
          class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        >
          <thead
            class="
              text-xs
              sticky
              top-0
              bg-white-100
              border-b border-black-200
              text-black-50
            "
          >
            <tr class="py-8">
              <th
                scope="col"
                class="
                  px-2
                  py-2.5
                  font-normal
                  border-r border-black-200
                  text-xs
                  lg:w-1/3
                  overflow-ellipsis overflow-clip
                "
              >
                Task name
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Asignee
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/5
                "
              >
                Due Date
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Priority
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  flex
                  justify-between
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/7
                "
              >
                Status
                <span><i class="fas fa-plus text-xs"></i></span>
              </th>
              <th
                scope="col"
                class="
                  px-2
                  py-2
                  font-normal
                  border-r border-black-200
                  text-xs
                  w-1/5
                "
              >
                Time
              </th>
            </tr>
          </thead>
          <tbody class="listBody">
            <tr
              class="
                newList
                bg-white
                border-b border-black-200
                text-black-100
                dark:bg-gray-800 dark:border-gray-700
                hover:bg-gray-100
              "
              v-for="task in myTask"
              :key="task.id"
            >
              <th
                scope="row"
                class="
                  flex
                  justify-between
                  px-4
                  py-2
                  font-normal
                  whitespace-nowrap
                  border-r border-black-200
                  overflow-ellipsis
                  md:overflow-clip
                "
              >
                <input
                  class="text-black-50 lg:w-full mr-10 placeholder-text-black-50"
                  contenteditable="true"
                  placeholder="Assign task"
                  :value="task.name"
                />
                <button
                  class="text-xs text-white-100 bg-black-50 rounded-md px-2"
                  @click="showTaskDetail(task)"
                  data-tooltip-target="detail-tooltip"
                  data-tooltip-placement="bottom"
                >
                  Detail
                </button>
                <div
                  id="detail-tooltip"
                  role="tooltip"
                  class="
                    inline-block
                    absolute
                    invisible
                    z-10
                    py-1
                    px-2
                    text-xs text-black-100
                    bg-white-100
                    rounded
                    border border-yellow-200
                    shadow-sm
                    opacity-0
                    tooltip
                  "
                >
                  View Details
                </div>
              </th>

              <!-- Select for user -->
              <td class="border-r border-black-200 relative">
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                    select
                  "
                  v-if="myAbilities.can('update_task', 'pms')"
                  :options="options"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="userSelect"
                  id="user"
                  track-by="id"
                  label="name"
                  v-model="task.user"
                  disabled
                >
                </multiselect>
              </td>
              <!-- User Select End -->

              <!-- date range picker -->
              <td class="px-3 border-r border-black-200">
                <date-picker
                  class="text-xs datepicker w-full pb-0 picker"
                  valueType="YYYY-MM-DD"
                  type="date"
                  placeholder="Deadline"
                  v-model="task.deadline"
                  @close="updateTaskDeadline(task.id, task.name)"
                  @change="saveDeadlineValue"
                >
                </date-picker>
              </td>
              <!-- date range picker end -->

              <!-- Select for priority -->
              <td class="border-r border-black-200">
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                  "
                  :options="TaskPriority"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select "
                  name="priority"
                  id="priority"
                  track-by="id"
                  label="value"
                  @input="updateTaskPriority(task)"
                  @select="savePriorityValue"
                  v-model="task.task_priority"
                >
                </multiselect>
              </td>
              <!-- priority select end -->

              <!-- Select for Status -->
              <td class="border-r border-black-200">
                <multiselect
                  class="
                    text-xs
                    w-full
                    border-0
                    mb-0
                    placeholder-mb-0
                    text-black-50
                  "
                  :options="task.project.task_status"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :allow-empty="false"
                  :searchable="true"
                  :show-labels="false"
                  placeholder="Select"
                  name="statusSelect"
                  id="status"
                  track-by="id"
                  label="name"
                  @input="
                    updateTaskStatus(task.id, task.name, task.task_status)
                  "
                  @select="saveStatusValue"
                  v-model="task.task_status"
                >
                </multiselect>
              </td>
              <!-- status end -->
              <td class="px-3 border-r border-black-200">
                <date-picker
                  class="text-xs datepicker border-0 w-full pb-0 picker"
                  valueType="HH:mm:ss"
                  type="time"
                  placeholder="Est. duration"
                  @close="updateTaskDuration(task.id, task.name)"
                  @change="saveDurationValue"
                  v-model="task.estimated_duration"
                >
                </date-picker>
              </td>
            </tr>
          </tbody>
          <!-- New Task -->
          <!-- <tr
            class="
              bg-white
              border-b border-black-200
              w-full
              text-black-100
              dark:bg-gray-800 dark:border-gray-700
              hover:bg-gray-100
              hidden
              newTask
            "
            id="newTask"
          >
            <th
              scope="row"
              class="font-normal whitespace-nowrap border-r border-black-200"
            >
              <input
                type="name"
                id="name"
                class="
                  bg-white-100
                  task-inp
                  border border-gray-300
                  text-gray-900 text-sm
                  rounded-lg
                  focus:ring-transparent
                  block
                  w-full
                  p-2.5
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:placeholder-gray-400
                  dark:text-white
                  dark:focus:ring-blue-500
                  dark:focus:border-blue-500
                  px-4
                "
                placeholder="Add task here ..."
                required
              />
              
            </th>
            <td class="border-r border-black-200">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                "
                :options="options"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select"
                :show-labels="false"
                name="userSelect"
                id="editUser"
              >
              </multiselect>
            </td>
            <td class="px-3 border-r border-black-200 truncate">
              <date-picker
                class="text-xs outline-none w-full pb-0"
                valueType="format"
                type="date"
                placeholder=""
              >
              </date-picker>
            </td>
            <td class="border-r">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                  select
                "
                :options="TaskPriority"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select "
                :show-labels="false"
                name="priority"
                id="priority"
                track-by="id"
                label="value"
              >
              </multiselect>
            </td>
     
            <td class="border-r border-black-200">
              <multiselect
                class="
                  text-xs
                  w-full
                  border-0
                  mb-0
                  placeholder-mb-0
                  text-black-50
                "
                :options="options"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                :allow-empty="false"
                :searchable="true"
                placeholder="Select"
                name="status"
                id="status"
                :show-labels="false"
              >
              </multiselect>
            </td>
            <td class="px-3 border-r border-black-200">
              <date-picker
                class="text-xs datepicker border-0 w-full pb-0"
                valueType="format"
                type="time"
                placeholder=""
              >
              </date-picker>
            </td>
            
          </tr> -->
        </table>
      </div>
    </div>
    <!-- Task Detail Sidebar -->
    <div
      class="
        flex
        justify-end
        max-w-xl
        hidden
        fixed
        z-10
        taskDetail
        right-0
        overflow-none
      "
    >
      <aside
        class="border border-black-200 bg-white-100 w-screen"
        aria-label="Sidebar"
      >
        <div class="flex flex-col h-screen bg-white-100">
          <header
            class="
              header
              border-b border-black-200
              py-4
              px-2
              flex
              justify-end
              items-center
            "
          >
            <div class="flex items-center">
              <div class="mr-4">
                <i
                  v-if="myAbilities.can('store_task_documents', 'pms')"
                  class="fas fa-paperclip text-black-50"
                  @click="toggleDocModal"
                ></i>
              </div>
              <nav class="flex pr-4" aria-label="Breadcrumb">
                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'MyTaskDetail',
                      }"
                      class="
                        inline-flex
                        pr-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Details
                    </router-link>
                    |
                  </li>
                  <li class="inline-flex items-center">
                    <router-link
                      :to="{
                        name: 'myQaStatus',
                        params: {
                          qaData: currentTaskQa,
                        },
                      }"
                      :param="currentTaskQa"
                      class="
                        inline-flex
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Q/A
                    </router-link>
                  </li>
                  <li class="inline-flex items-center">
                    |
                    <router-link
                      :to="{
                        name: 'myReview',
                        params: {
                          reviewData: this.currentTaskReview,
                        },
                      }"
                      class="
                        inline-flex
                        pl-2
                        items-center
                        text-sm
                        font-medium
                        text-gray-700
                        hover:text-gray-900
                        dark:text-gray-400 dark:hover:text-white
                      "
                    >
                      Review
                    </router-link>
                  </li>
                </ol>
              </nav>
              <button class="ml-3" @click="hideDetail">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </header>
          <main class="flex-1 overflow-y-auto">
            <router-view
              :default="{ name: 'MyTaskDetail' }"
              @update-task-name="updataTaskName"
              @update-task-desc="updataTaskDescription"
            >
            </router-view>
          </main>

          <div class="py-1 px-5 space-x-2 rounded-b shadow-md">
            <div class="flex justify-end pt-16">
              <button
                class="p-2 bg-blue-500 text-white-100 font-bold rounded-lg"
                @click="addComment"
              >
                Comment
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>


    <!-- document Modal -->
    <div
      class="fixed z-10 overflow-y-auto top-0 w-full left-0 hidden"
      id="taskDocModal"
    >
      <div
        class="
          flex
          items-center
          justify-center
          min-height-100vh
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <!-- Backdrop -->
        <div class="fixed inset-0 transition-opacity">
          <div class="absolute inset-0 bg-gray-900 opacity-75" />
        </div>
        <!-- Backdrop End -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen"
          >&#8203;</span
        >

        <!-- Modal Body -->

        <div
          class="
            inline-block
            align-center
            bg-white
            rounded-lg
            text-left
            overflow-hidden
            shadow-xl
            transform
            transition-all
            sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
          "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            class="
              flex
              justify-between
              items-center
              p-5
              rounded-t
              border-b
              dark:border-gray-600
            "
          >
            <h3 class="text-xl font-medium text-black-100 dark:text-white">
              Add Files
            </h3>
            <!-- Close button -->
            <button
              type="button"
              class="
                text-gray-400
                bg-transparent
                hover:bg-gray-200 hover:text-gray-900
                rounded-lg
                text-sm
                p-1.5
                ml-auto
                inline-flex
                items-center
                dark:hover:bg-gray-600 dark:hover:text-white
              "
              @click="toggleDocModal"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div class="p-6 space-y-6">
            <form class="space-y-6" @submit.prevent="addTaskDoc">
              <input
                class="
                  block
                  w-full
                  mb-5
                  text-sm text-gray-900
                  border border-gray-300
                  rounded-lg
                  cursor-pointer
                  bg-white-100
                  focus:outline-none
                "
                id="default_size"
                type="file"
                @change="mapFile"
              />
              <div class="flex items-center"></div>

              <div class="flex justify-center">
                <div class="flex items-center">
                  <button
                    type="submit"
                    class="
                      w-lg
                      text-white-100
                      bg-red-400
                      hover:bg-pink-50
                      focus:ring-4 focus:outline-none focus:ring-red-300
                      font-medium
                      rounded-lg
                      text-sm
                      px-5
                      py-2.5
                      text-center
                      dark:bg-red-400
                    "
                  >
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import $ from "jquery";
export default {
  name: "MyTaskList",

  data() {
    return {
      newTask: {
        name: "",
        assignTo: null,
        priority: null,
        status: null,
        estimatedDuration: null,
        deadline: null,
      },
      currentTaskId: null,
      currentDeadline: null,
      currentPriority: null,
      currentTaskQa: {},
      currentTaskReview: {},
      doc: {
        file: null,
      },
      options: ["Ali", "Maham"]
    };
  },
  methods: {
    ...mapActions([
      "fetchPriority",
      "storeTask",
      "updateMyTask",
      "fetchTask",
      "storeMyTaskComment",
      "storeTaskDocument",
      "updateTaskComment",
      "fetchProject",
      "fetchMyTasks",
    ]),
    ...mapMutations(["toggleLoading"]),

// toggle task document modal
    toggleDocModal() {
      this.doc.file = "";
      $("#taskDocModal").toggleClass("hidden");
    },

    //open task status add modal
    callTaskStatusModal() {
      this.$router.replace(`/projectOverview/${this.$route.params.id}`);
      this.$eventBus.$emit("callTaskStatusModal");
    },

    //access selected priority value
    savePriorityValue(item) {
      this.currentPriority = item;
    },
    //task priority
    async updateTaskPriority(task) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: task.id,
        name: task.name,
        priority: this.currentPriority.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //----- update task ----------
    async updataTaskName(name, id) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //update task description
    async updataTaskDescription(descr, id, name) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
        description: descr,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
    },

    //access selected status value
    saveStatusValue(item) {
      this.currentStatus = item;
    },
    //task status
    async updateTaskStatus(item, name) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: item,
        name: name,
        status_id: this.currentStatus.id,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //access selected deadline value
    saveDeadlineValue(item) {
      this.currentDeadline = item;
    },

    //  update task Deadline
    async updateTaskDeadline(id, name) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
        deadline: this.currentDeadline,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    //------comment --------
    async addComment() {
      this.toggleLoading(true);
      let res = await this.storeMyTaskComment({
        description: this.comment.description,
        task_id: this.$route.params.id,
      });
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.comment.description = "";
      } else {
        this.$toasted.error(res.msg);
      }
    },

    //access selected duration value
    saveDurationValue(item) {
      this.currentDuration = item;
    },

    //task Duration
    async updateTaskDuration(id, name) {
      this.toggleLoading(true);
      let res = await this.updateMyTask({
        id: id,
        name: name,
        estimated_duration: this.currentDuration,
      });
      if (res.success) {
        this.$toasted.success(res.msg);
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleLoading(false);
    },

    // show detail sidebar
    async showTaskDetail(task) {
      let id = task.id;
      this.crntTask = task;
      this.currentTaskId = task.id;
      this.$router
        .push({ name: "MyTaskDetail", params: { taskId: this.currentTaskId } })
        .catch(() => {});
      this.toggleLoading(true);
      await this.fetchTask({ id });
      let sidebar = $(".taskDetail");
      if (sidebar.hasClass("hidden")) {
        sidebar.removeClass("hidden");
      }
      this.toggleLoading(false);
      this.newFunction();
      this.currentReview();
    },

    // hide detail sidebar
    hideDetail() {
      let stateObj = { id: "22" };
      window.history.replaceState(stateObj, "MyTaskList", `/myTask`);
      $(".taskDetail").toggleClass("hidden");
    },

    // for current qa task, for qa status
    newFunction() {
      if (this.Task.task_qa.length != 0) {
        let taskQa = this.Task.task_qa;
        let currentTaskQa = {};
        taskQa.forEach((element) => {
          if (element.end_time == null) {
            currentTaskQa = element;
          }
        });
        this.currentTaskQa = currentTaskQa;
      }
    },

    // For current review task, Review status
    currentReview() {
      if (this.Task.task_review.length != 0) {
        let taskReview = this.Task.task_review;
        let currentTaskReview = {};
        taskReview.forEach((element) => {
          if (element.end_time == null) {
            currentTaskReview = element;
          }
        });
        this.currentTaskReview = currentTaskReview;
      }
    },

    //add task doc
    mapFile(e) {
      this.doc.file = e.target.files[0];
    },
    async addTaskDoc() {
      this.toggleLoading(true);
      let formdata = new FormData();
      formdata.append("file", this.doc.file);
      formdata.append("task_id", this.$route.params.taskId);
      let res = await this.storeTaskDocument(formdata);
      this.toggleLoading(false);
      if (res.success) {
        this.$toasted.success(res.msg);
        this.doc.file = "";
      } else {
        this.$toasted.error(res.msg);
      }
      this.toggleDocModal();
    },
  },
  computed: {
    ...mapGetters(["Project", "TaskPriority", "Task", "myTask", "myAbilities"]),
  },
  async created() {
    let id = 0;
    this.toggleLoading(true);
    await this.fetchMyTasks({ id });
    await this.fetchPriority();
    this.toggleLoading(false);
  },
  async mounted() {
//  this.$eventBus.$on("callMethodIndetail", () => {
//       this.showTaskDetail;
//     });

    

    let url = window.location.href;
    let segment = url.split("/").pop();
    if (segment == "myTaskDetail") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "myTaskreview") {
      $(".taskDetail").removeClass("hidden");
    }
    if (segment == "myTaskqastatus") {
      $(".taskDetail").removeClass("hidden");
    }

    let id = 0;
    this.toggleLoading(true);
    await this.fetchMyTasks({ id });
    this.toggleLoading(false);
  },
};
</script>
